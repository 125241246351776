<template>
    <page-header-wrapper>
        <template slot="extra">
            <a-button type="primary" @click="add" v-action:add>新增</a-button>
        </template>
        <a-card :bordered="false">
            <div class="table-page-search-wrapper" v-action:query>
                <a-form layout="inline">
                    <a-row :gutter="24">
                        <a-col :md="8" :sm="24">
                            <a-form-item label="时间筛选：">
                                <a-range-picker @change="getDate" v-model="queryParam.time" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24">
                            <a-form-item label="关键词">
                                <a-input style="width: 80%" placeholder="请输入" v-model="queryParam.keyword" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="4 || 24" :sm="24">
                            <span class="table-page-search-submitButtons">
                                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                                <a-button style="margin-left: 8px" @click="reset">重置</a-button>
                            </span>
                        </a-col>
                    </a-row>
                </a-form>
            </div>

            <s-table ref="table" size="default" :rowKey="(record) => record.id" :columns="columns" :data="loadData" showPagination="auto" :pageNum="pageNum">
                <span slot="serial" slot-scope="text, record, index">
                    {{ index + 1 }}
                </span>
				<span slot="gstitle" slot-scope="gstitle,record">
					<span @click="goUrl(record.link_url)" style="cursor:pointer">{{gstitle}}</span>
				</span>
                <span slot="time" slot-scope="create_time">
                    {{create_time | time}}
                </span>
                <span slot="operation">
                    --
                </span>
                <span slot="action" slot-scope="text, record">
                    <template>
                        <!-- <a @click="handleEdit(record)">编辑</a>
                        <a-divider type="vertical" /> -->
                        <a @click="handleDel(record)" v-action:del>删除</a>
                    </template>
                </span>
            </s-table>

          
            <!-- 发布 -->
            <a-modal class="modal_foot_center" v-model="modalVisible" title="发布供水之声" centered @ok="closeModal(1)" @cancel="closeModal(0)">
				<a-spin tip="正在加载..." :spinning="spinning">
					<div>
						<a-alert message="仅支持肥西供水发布的微信推文链接" type="info" show-icon />
						<div class="deliv_item">
							<div class="deliv_item_label"><i>*</i>微信图文链接</div>
							<div style="width:80%"><a-input placeholder="请输入微信图文链接" v-model="url" @change="getUrl" /></div>
						</div>
						<div v-show="title" class="modal_item">
							<div>标题</div>
							<div>{{title}}</div>
						</div>
						<div v-show="img_url" class="modal_item">
							<div>封面图</div>
							<div><img :src="'http://wximg.ibusad.com/img.php?url='+img_url" alt="" height="100px"></div>
						</div>
					</div>
				</a-spin>
            </a-modal>
        </a-card>
    </page-header-wrapper>
</template>

<script>
    import moment from 'moment'
    import { Modal,message } from 'ant-design-vue';
    import {
        STable,
        Ellipsis
    } from '@/components'
    import {
        getList,
        deliv,
        getWxInfo,
		delWxArticle
    } from '@/api/article'
	let timer
    const columns = [
        {
            title: '序号',
            scopedSlots: {
                customRender: 'serial'
            },
            align:'center',
            width:'90px'
        },
        {
            title: '文章标题',
            dataIndex: 'title',
			scopedSlots:{
				customRender:'gstitle'
			},
            align:'left'
        },
        {
            title: '操作人',
            dataIndex: '',
            align:'center',
            scopedSlots: {
                customRender: 'operation'
            },
        },
        {
            title: '发布时间',
            dataIndex: 'create_time',
            scopedSlots: {
                customRender: 'time'
            },
            align:'center'
        },
        {
            title: '操作',
            dataIndex: 'action',
            width: '150px',
            scopedSlots: {
                customRender: 'action'
            },
            align:'center'
        }
    ]

    const statusMap = {
        0: {
            status: 'default',
            text: '全部'
        },
        1: {
            status: 'default',
            text: '待审核'
        },
        2: {
            status: 'success',
            text: '审核通过'
        },
        3: {
            status: 'error',
            text: '审核拒绝'
        }
    }

    export default {
        name: '',
        components: {
            STable,
            Ellipsis
        },
        data() {
            this.columns = columns
            return {
                visible: false,
                // 查询参数
                queryParam: {
                    time: [],
                    keyword: '',
                    startTime: '',
                    endTime: '',
                },
                // 加载数据方法 必须为 Promise 对象
                loadData: parameter => {
                    //console.log('parameter',parameter)
                    const requestParameters = Object.assign({}, parameter, this.queryParam)
                    //console.log('requestParameters',requestParameters)
                    return getList(requestParameters)
                        .then(res => {
                            return res.data
                        })
                },
                
                modalVisible: false, //发布弹窗
                url:'',   //微信文章url
                title:'',
                img_url:'',
                link_url:'',
				spinning:false,
                pageNum:1,
            }
        },
		crteated(){
			let current = getUrlKey('p')||0
			//console.log('current',current)
			this.pageNum = Number(current)
		},
        methods: {
            add(){   //新增
                this.modalVisible = true
            },
            closeModal(status) { //回复弹窗关闭 1确定 2取消
                //console.log('status',status)
                let that = this
                if(status==1){
					
                    let url = this.url
                    let title = this.title
                    let img_url = this.img_url
                    let params = {
                        link_url:url,
                        img_url:img_url,
                        title:title
                    }
					if(!url){
						this.$message.error('请输入链接')
						return false
					}else if(!this.fCheckUrl(url)){
					    this.$message.error('链接格式错误')
					    return false
					}
                    deliv(params).then(res =>{
                        console.log('res',res)
                        if(res.code==200){
                            this.$message.success('发布成功')
                            this.$refs.table.refresh(true)
                            this.modalVisible = false
							this.url = ''
							this.title = ''
							this.img_url = ''
                        }else{
							this.$message.error(res.msg)
						}
                    })
                }else{
                    this.url = ''
                    this.title = ''
                    this.img_url = ''
                }
            },
            getUrl(e){
                //console.log('e',e.target.value);
                let url = e.target.value
                if(!url){
                    this.$message.error('请输入链接')
                    return false
                }
                let params = {
                    url:url
                }
				
				if(timer){
					clearTimeout(timer)
				}
				timer = setTimeout(() => {
					this.spinning = true
					getWxInfo(params).then(res =>{
					    if(res.code==200){
					        this.title = res.data.title
					        this.img_url = res.data.image
							this.spinning = false
					    }else{
					        this.$message.error(res.msg)
							this.spinning = false
					    }
					    
					})
			    }, 800)  
            },
            fCheckUrl(str) {
                var oRegUrl = new RegExp();
                oRegUrl.compile("^[A-Za-z]+://[A-Za-z0-9-_]+\\.[A-Za-z0-9-_%&\?\/.=]+$");  
                if (!oRegUrl.test(str)) {
                    return false;
                }
                return true;
            },
            getDate(date, dateString) { //时间筛选
                this.queryParam.time = dateString
                this.queryParam.startTime = this.queryParam.time[0] ? this.queryParam.time[0]+' 00:00:00': ''
                this.queryParam.endTime = this.queryParam.time[1] ? this.queryParam.time[1]+' 23:59:59' : ''
            },
            handleEdit(data){  //编辑

            },
            handleDel(data){   //删除
				let that = this
				Modal.confirm({
					content:'确认删除吗?',
					onOk(){
						let params = {
							id:data.id
						}
						delWxArticle(params).then(res =>{
							console.log('res',res);
							if(res.code==200){
								that.$message.success('删除成功')
								that.$refs.table.refresh(true)
							}
						})
					},
					onCancel(){
						
					}
				})
            },
            reset() { //重置
                this.queryParam.time = null
				this.queryParam.startTime = ''
				this.queryParam.endTime = ''
                this.queryParam.status = '0'
                this.queryParam.keyword = ''
                this.$refs.table.refresh(true)
            },
			goUrl(url){  //跳转
				if(url){
					window.open(url)
				}
			}
        },
        filters: {
            time(t) {
                if (t) {
                    return moment(parseInt(t) * 1000).format('YYYY/MM/DD HH:mm:ss')
                } else {
                    return t
                }
            },
            statusFilter(type) {
                return statusMap[type].text
            },
            statusTypeFilter(type) {
                return statusMap[type].status
            }
        }
    }
</script>


<style scoped>
.deliv_item{display:flex;align-items: center;margin-top: 36px;}
.deliv_item_label{width: 120px;}
.deliv_item_label i{font-style: normal;color: #FF3B30;}
.modal_item{display: flex;margin-top: 10px;}
.modal_item div:nth-child(1){min-width: 112px;}
.modal_item div:nth-child(2) img{margin-top: 5px;}
</style>